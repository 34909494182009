
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$edify-primary: mat.define-palette(mat.$indigo-palette);
$edify-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$edify-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$edify-theme: mat.define-light-theme((
  color: (
    primary: $edify-primary,
    accent: $edify-accent,
    warn: $edify-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($edify-theme);

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
// Import Angular Material theming functions
:root {
  --accentbg: rgba(195, 218, 254, 1);
  --accentdefault: rgba(127, 156, 245, 1);
  --accentonaccent: rgba(255, 255, 255, 1);
  --bgdefault: rgba(255, 255, 255, 1);
  --bgmuted: rgba(247, 250, 252, 1);
  --bgsubtle: rgba(237, 242, 247, 1);
  --boxshadow-default: 5px 5px 5px 3px rgba(26, 32, 44, 0.15);
  --brandprimary: rgba(15, 19, 81, 1);
  --brandsecondary: rgba(196, 192, 191, 1);
  --brandtertiary: rgba(252, 251, 247, 1);
  --buttonprimarybackground: rgba(127, 156, 245, 1);
  --buttonprimarytext: rgba(255, 255, 255, 1);
  --caption-medium-font-family: "Inter", Helvetica;
  --caption-medium-font-size: 12px;
  --caption-medium-font-style: normal;
  --caption-medium-font-weight: 500;
  --caption-medium-letter-spacing: 0px;
  --caption-medium-line-height: 16px;
  --cardbackground: rgba(255, 255, 255, 1);
  --fgdefault: rgba(0, 0, 0, 1);
  --fgmuted: rgba(74, 85, 104, 1);
  --fgsubtle: rgba(160, 174, 192, 1);
  --list-item-boarder-grey:#d0d5df;
  --focused-state-gray: 0px 0px 0px 2px rgba(242, 244, 247, 1);
  --iconprimary: rgba(28, 39, 76, 1);
  --neutral-gray200: rgba(228, 231, 236, 1);
  --neutral-gray300: rgba(206, 210, 218, 1);
  --primary: rgba(84, 95, 113, 1);
  --primary-200: rgba(153, 194, 255, 1);
  --primary-500: rgba(26, 117, 255, 1);
  --primary-700: rgba(0, 82, 204, 1);
  --shadowsdefault: rgba(26, 32, 44, 1);
  --typography-body-font-family: "Roboto", Helvetica;
  --typography-body-font-size: 16px;
  --typography-body-font-style: normal;
  --typography-body-font-weight: 400;
  --typography-body-letter-spacing: 0px;
  --typography-body-line-height: 110.00000238418579%;
  --typography-h1-bold-font-family: "Inter", Helvetica;
  --typography-h1-bold-font-size: 48.82899856567383px;
  --typography-h1-bold-font-style: normal;
  --typography-h1-bold-font-weight: 700;
  --typography-h1-bold-letter-spacing: -2.4414499282836912px;
  --typography-h1-bold-line-height: 110.00000238418579%;
  --typography-h1-regular-font-family: "Inter", Helvetica;
  --typography-h1-regular-font-size: 48.82899856567383px;
  --typography-h1-regular-font-style: normal;
  --typography-h1-regular-font-weight: 400;
  --typography-h1-regular-letter-spacing: -2.4414499282836912px;
  --typography-h1-regular-line-height: 110.00000238418579%;
  --typography-h2-bold-font-family: "Inter", Helvetica;
  --typography-h2-bold-font-size: 39.0629997253418px;
  --typography-h2-bold-font-style: normal;
  --typography-h2-bold-font-weight: 700;
  --typography-h2-bold-letter-spacing: -1.9531499862670898px;
  --typography-h2-bold-line-height: 110.00000238418579%;
  --typography-h2-regular-font-family: "Inter", Helvetica;
  --typography-sidemenu-font-regular: "Poppins-Regular", Helvetica;
  --typography-poppins-medium:"Poppins-Medium", Helvetica;
  --typography-h2-regular-font-size: 39.0629997253418px;
  --typography-h2-regular-font-style: normal;
  --typography-h2-regular-font-weight: 400;
  --typography-h2-regular-letter-spacing: -1.9531499862670898px;
  --typography-h2-regular-line-height: 110.00000238418579%;
  --white: rgba(255, 255, 255, 1);
  --red: rgba(255, 0, 0);
  --grey: rgba(128, 128, 128);
  --neutral-gray300: rgba(206, 210, 218, 1);
  --neutral-gray50: rgba(249, 250, 251, 1);
  --neutral-gray700: rgba(52, 64, 81, 1);
  --paragraph-m-semi-bold-font-family: "Inter", Helvetica;
  --paragraph-m-semi-bold-font-size: 16px;
  --paragraph-m-semi-bold-font-style: normal;
  --paragraph-m-semi-bold-font-weight: 600;
  --paragraph-m-semi-bold-letter-spacing: 0px;
  --paragraph-m-semi-bold-line-height: 24px;
  --paragraph-s-semi-bold-font-family: "Inter", Helvetica;
  --paragraph-s-semi-bold-font-size: 14px;
  --paragraph-s-semi-bold-font-style: normal;
  --paragraph-s-semi-bold-font-weight: 600;
  --paragraph-s-semi-bold-letter-spacing: 0px;
  --paragraph-s-semi-bold-line-height: 20px;
  --student-book-button:#33A9FF
}

body {
  margin: 0px;
  height: 100%;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mdc-evolution-chip-set__chips {
  display: flex;
  flex-flow: unset !important;
  min-width: 0;

}

::ng-deep .multiselect-dropdown[_ngcontent-ng-c692748064] .dropdown-btn[_ngcontent-ng-c692748064] {
  display: block !important;
  border: 1px solid #adadad;
  flex-grow: 1 !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
}

.cal-week-view .cal-day-headers span {
  opacity: 1;
}

.cal-week-view .cal-header.cal-today {
  background-color: #1A75FF;
  color: white;
}

.cal-week-view .cal-header.cal-today b {
  font-weight: 500;
}

.cal-week-view .cal-header.cal-today span {
  font-weight: 300;
}

.cal-week-view .cal-event {
  border: unset;
  border-left: 5px solid #66BFFF;
  font-weight: 600;
  color: black;
}

.mat-progress-bar-fill::after {
  background-color: var(--primary-700) !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }






@media (max-width: 768px) {html, body { height: 100%; }}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
